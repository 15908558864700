// import '@babel/polyfill';
// import 'mutationobserver-shim';
import Vue from 'vue';
import AOS from 'aos'
import 'aos/dist/aos.css' 
import "@/assets/styles/app.scss";
import './plugins/bootstrap-vue';
import './plugins/page-transition';
import './plugins/parallax-js';
import VueScreen from 'vue-screen';
import App from './App.vue';
import router from './router';

// Vue.config.productionTip = false;

const VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);
Vue.use(VueScreen);

Vue.component('app', App);

(async () => {
  return new Vue({
    el: '#app',
    router,
    render: (h) => h(App),
    mounted() {
      AOS.init()
    },
  });
})();