<template>
  <FooterContainer class="Fc">
    <b-container class="maxFooter">
      <b-row>
        <!-- <b-col>
          <div class="fListContainer">
            <h6 class="titleLink">Valued Features</h6>
            <router-link to="/">21 First Century Schools</router-link>
            <router-link to="/">Communicate</router-link>
            <router-link to="/">Collaborate</router-link>
            <router-link to="/">Create</router-link>
          </div>
        </b-col> -->
        <b-col>
          <div class="fListContainer">
            <h6 class="titleLink">About Us</h6>
            <b-link @click="scrollToTop()">Who is whiteStrings</b-link>
            <b-link @click="scrollToTop()">Why whiteStrings</b-link>
            <b-link @click="scrollToTop()"
              >How can whiteStrings help you</b-link
            >
          </div>
        </b-col>
        <b-col>
          <div class="fListContainer">
            <h6 class="titleLink">Services</h6>
            <b-link @click="scrollToTop()">System Features</b-link>
            <b-link @click="scrollToTop()">For School Leaders</b-link>
            <b-link @click="scrollToTop()">For Teachers and Tutors</b-link>
            <b-link @click="scrollToTop()">For Students and Learners</b-link>
            <b-link @click="scrollToTop()">For Parents and Partners</b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <hrLine />
    <b-container class="maxFooter">
      <b-row>
        <b-col class="flexSB">
          <p>Copyright © 2021 whiteStrings. | All rights reserved.</p>
          <p>Philippines</p>
        </b-col>
      </b-row>
    </b-container>
  </FooterContainer>
</template>
<script>
import styled from "vue-styled-components";

const FooterContainer = styled.div`
  height: auto;
  width: 100%;
  padding: 4vw 0 1vw 0;
  background: #f7f7f7;
`;

const hrLine = styled.hr``;

export default {
  name: "FooterContent",
  components: {
    FooterContainer,
    hrLine,
  },
  methods: {
    // goToHome(refName) {
    //   // this.$router.push("/about");
    //   var element = this.$refs[refName];
    //   var top = element.offsetTop;

    //   window.scrollTo(0, top);
    // },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/footer.scss";
</style>
