<template>
  <homeContainer class="Hm">
    <bannerContainer>
      <b-container>
        <b-row>
          <b-col>
            <Title data-aos="zoom-in">Schools Reimagined</Title>
            <subTitle
              style="margin-bottom: 0"
              data-aos="zoom-in"
              data-aos-duration="1500"
              >Let us help you navigate <br />
              through the future of
              <span
                class="lightSeaGreen"
                data-aos="zoom-in"
                data-aos-duration="1500"
                ><b>Learning</b></span
              >
            </subTitle>
            <b-button
              class="btnBook"
              data-aos="zoom-in"
              data-aos-duration="2500"
              v-scroll-to="'#partnershipForm'"
              >Book a Demo Now</b-button
            >
          </b-col>
        </b-row>
      </b-container>
      <bannerImg data-aos="fade-up" data-aos-duration="3000" />
    </bannerContainer>
    <servicesContainer class="S">
      <headerContainer>
        <b-container>
          <b-row>
            <b-col>
              <TitleReg data-aos="zoom-in"> Easier than Ever</TitleReg>
              <subTitle data-aos="zoom-in" data-aos-duration="1500">
                Learn about our services for each role inside the school.
              </subTitle>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <b-button class="scrllTo" v-scroll-to="'#schoolLeader'">
                <img :src="leaders" alt="school leaders" data-aos="zoom-in" />
                <p
                  class="lightSeaGreen"
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                >
                  For School Leaders
                </p>
              </b-button>
            </b-col>
            <b-col cols="3">
              <b-button class="scrllTo" v-scroll-to="'#teachersTutor'">
                <img
                  :src="teachers"
                  alt="teachers and tutors"
                  data-aos="zoom-in"
                />
                <p
                  class="lightSeaGreen"
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                >
                  For Teachers and Tutors
                </p>
              </b-button>
            </b-col>
            <b-col cols="3">
              <b-button class="scrllTo" v-scroll-to="'#students'">
                <img :src="students" alt="students" data-aos="zoom-in" />
                <p
                  class="lightSeaGreen"
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                >
                  For Students and Learners
                </p>
              </b-button>
            </b-col>
            <b-col cols="3">
              <b-button class="scrllTo" v-scroll-to="'#parents'">
                <img :src="parents" alt="parents" data-aos="zoom-in" />
                <p
                  class="lightSeaGreen"
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                >
                  For Parents and Partners
                </p>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </headerContainer>
      <b-container class="laptopContainer" fluid>
        <b-row>
          <b-col>
            <h1>School Management System</h1>
            <p>
              The All-in-One platform you’ll ever need <br />
              for your admin operations.<br/>
              <span class="lightSeaGreen">Learn more.</span>
            </p>
          </b-col>
        </b-row>
        <img
          :src="require('../assets/images/home/landing-laptop.png')"
          alt=""
        />
      </b-container>
      <b-container class="greenContainer" fluid>
        <b-row>
          <b-col>
            <p>
              whiteStrings assists educational institutions in modernizing
              operations, <br />transforming teaching and learning, and
              enhancing community-wide <br />
              engagement across a hyperconnected digital landscape.
            </p>
            <router-link to="/about-us" class="lnkLearnMore"
              >Learn more about us</router-link
            >
          </b-col>
        </b-row>
        <img :src="require('../assets/images/home/greenBg.png')" alt="" />
      </b-container>
      <b-container class="analyticsContainer" fluid>
        <b-row>
          <b-col>
            <h1>Data & Analytics</h1>
            <p>Analyze now decide later.</p>
            <router-link to="/learn-more" class="lnkLearnmore"
              >Learn more</router-link
            >
          </b-col>
        </b-row>
        <img :src="require('../assets/images/home/analyticsBg.png')" alt="" />
      </b-container>
      <b-container class="partnersContainer" fluid>
        <b-row>
          <b-col>
            <h1>Our Partners</h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <img :src="require('../assets/images/home/aws-logo.png')" alt="" />
          </b-col>
          <b-col>
            <img :src="require('../assets/images/home/olpmc.png')" alt="" />
          </b-col>
          <b-col>
            <img :src="require('../assets/images/home/Pag-IBIG.png')" alt="" />
          </b-col>
        </b-row>
      </b-container>
      <b-container class="partnershipForm" fluid id="partnershipForm">
        <b-row>
          <b-col class="colLeft">
            <h1>
              Be part of our Beta-School <br />
              partnership program
            </h1>
            <p>
              Our experts are with you each step<br />
              of the way to help your school <br />
              goals and increase your <br />
              success.
            </p>
          </b-col>
          <b-col class="colRight">
            <h4>
              Let us know what you need and we’ll be happy to <br />
              provide you the perfect platform for your school.
            </h4>

            <b-form>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    id="input-group-1"
                    label="First Name*"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      type="name"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    id="input-group-1"
                    label="Last Name*"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      type="email"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    id="input-group-1"
                    label="Business Name*"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      type="name"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    id="input-group-1"
                    label="Mobile Name*"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      type="email"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    id="input-group-1"
                    label="Country*"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      type="name"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    id="input-group-1"
                    label="Which Type of School are you?*"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      type="email"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <span class="consent">
                I consent to receive communications from whiteStrings containing
                news, updates and<br />
                promotions about whiteStrings and its products and services. I
                understand that I can withdraw<br />
                my consent at anytime.
              </span>

              <b-form-checkbox
                id="checkbox-1"
                v-model="status"
                name="checkbox-1"
                value="accepted"
                unchecked-value="not_accepted"
                class="checkboxWs"
              >
                I agree to receive email and mobile communications from
                whiteStrings.*
              </b-form-checkbox>

              <span class="consent">
                By clicking submit below, you consent to allow whiteStrings to
                store and process the personal <br />
                information submitted above to provide you the content
                requested.
              </span>
              <div class="btnContainer">
                <b-button class="btnRegister">Register</b-button>
              </div>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
      <!-- <serveEveryone class="serve">
        <b-container class="max80">
          <b-row class="pT" id="schoolLeader">
            <b-col cols="6">
              <kinesis-container event="scroll">
                <kinesis-element :strength="70" axis="x">
                  <wTitle>Managing schools better in the 21st century.</wTitle>
                </kinesis-element>
                <kinesis-element :strength="60" axis="x">
                  <wSubTitle class="text-muted">
                    <b class="black">whiteStrings</b> equips
                    <b class="black">school leaders</b>
                    to modernize operations while lowering costs and increasing
                    productivity.
                    <br /><br />

                    With streamlined processes and freed up resources, schools
                    are enabled to deliver higher service quality to families,
                    at a much better pricing. Giving more value to the
                    hard-earned money of
                    <b class="black">parents</b>. <br /><br />

                    <b class="black">Schools Leaders</b> and
                    <b class="black">Parents</b>
                    can now collaborate and work together on what matters most.
                    To prepare our children for success in the new world and
                    future economy.
                    <b-button
                      v-b-toggle.systemFeaturesCollapse
                      class="btnCollapseme"
                      >View applied features</b-button
                    >
                  </wSubTitle>
                </kinesis-element>
              </kinesis-container>
            </b-col>
            <b-col>
              <kinesis-container event="scroll">
                <svgContainer>
                  <kinesis-element :strength="-30" axis="x" type="depth">
                    <img
                      :src="schoolLeaderBg"
                      alt="background"
                      class="oneSvg"
                    />
                  </kinesis-element>
                  <kinesis-element :strength="50" axis="x" type="depth">
                    <img
                      :src="schooLeaderLeftWindow"
                      alt="background"
                      class="oneSvg"
                    />
                  </kinesis-element>
                  <kinesis-element :strength="50" axis="x" type="depth">
                    <img
                      :src="schooLeaderMidWindow"
                      alt="books"
                      class="oneSvg"
                    />
                  </kinesis-element>
                  <kinesis-element :strength="50" axis="x" type="depth">
                    <img
                      :src="schooLeaderRightWindow"
                      alt="background"
                      class="oneSvg"
                    />
                  </kinesis-element>
                  <kinesis-element :strength="70" axis="x" type="depth">
                    <img
                      :src="schooLeaderPerson"
                      alt="background"
                      class="oneSvg"
                    />
                  </kinesis-element>
                </svgContainer>
              </kinesis-container>
            </b-col>
            <b-collapse id="systemFeaturesCollapse">
              <systemFeatures class="s2">
                <b-container>
                  <b-row>
                    <b-col
                      cols="3"
                      v-for="features in leaderFeature"
                      :key="features.id"
                    >
                      <div class="featuresList">
                        <img
                          :src="features.img"
                          :alt="features.alt"
                          data-aos="zoom-in"
                        />
                        <p data-aos="zoom-in" data-aos-duration="1500">
                          {{ features.name }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </systemFeatures>
            </b-collapse>
          </b-row>
          <b-row class="pT" id="teachersTutor">
            <b-col>
              <kinesis-container event="scroll">
                <svgContainer>
                  <kinesis-element :strength="-30" axis="x" type="depth">
                    <img :src="teacherBg" alt="background" class="oneSvg" />
                  </kinesis-element>
                  <kinesis-element :strength="50" axis="x" type="depth">
                    <img :src="teacherBooks" alt="background" class="oneSvg" />
                  </kinesis-element>
                  <kinesis-element :strength="50" axis="x" type="depth">
                    <img :src="teacherCam" alt="books" class="oneSvg" />
                  </kinesis-element>
                  <kinesis-element :strength="70" axis="x" type="depth">
                    <img :src="teacherLaptop" alt="background" class="oneSvg" />
                  </kinesis-element>
                  <kinesis-element :strength="70" axis="x" type="depth">
                    <img :src="teacherPerson" alt="background" class="oneSvg" />
                  </kinesis-element>
                  <kinesis-element :strength="50" axis="x" type="depth">
                    <img :src="teacherTable" alt="background" class="oneSvg" />
                  </kinesis-element>
                  <kinesis-element :strength="50" axis="x" type="depth">
                    <img :src="teacherTv" alt="background" class="oneSvg" />
                  </kinesis-element>
                </svgContainer>
              </kinesis-container>
            </b-col>
            <b-col cols="6">
              <kinesis-container event="scroll">
                <kinesis-element :strength="-70" axis="x">
                  <wTitle class="right"
                    >Managing classes better in the 21st century.</wTitle
                  >
                </kinesis-element>
                <kinesis-element :strength="-60" axis="x">
                  <wSubTitle class="right text-muted">
                    <b class="black">whiteStrings</b> empowers
                    <b class="black">teachers</b>
                    to transform the learning experience of
                    <b class="black">students</b>
                    by becoming a 21st Century Educator.
                    <br /><br />

                    With the technology changing industries exponentially on a
                    regular basis, the knowledge and skills learned by students
                    in schools may be obsolete by the time they enter the
                    workforce.
                    <br /><br />

                    <b class="black">whiteStrings</b> equips
                    <b class="black">teachers</b>
                    to prepare our
                    <b class="black">students</b>
                    and learners of today. So that they may have the mind sets
                    and skill sets of a confident and independent learner
                    entering into a complex and interdependent future economy.
                    <b-button
                      v-b-toggle.systemFeaturesCollapse2
                      class="btnCollapsemeright"
                      >View applied features</b-button
                    >
                  </wSubTitle>
                </kinesis-element>
              </kinesis-container>
            </b-col>
            <b-collapse id="systemFeaturesCollapse2">
              <systemFeatures class="s2">
                <b-container>
                  <b-row>
                    <b-col
                      cols="3"
                      v-for="features in teacherFeature"
                      :key="features.id"
                    >
                      <div class="featuresList">
                        <img
                          :src="features.img"
                          :alt="features.alt"
                          data-aos="zoom-in"
                        />
                        <p data-aos="zoom-in" data-aos-duration="1500">
                          {{ features.name }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </systemFeatures>
            </b-collapse>
          </b-row>
        </b-container>
      </serveEveryone> -->
    </servicesContainer>
    <!-- <beta-schools /> -->
  </homeContainer>
</template>

<script>
import styled from "vue-styled-components";
import bg from "../assets/images/home/banner-ver-2.0.png";
// import { KinesisContainer, KinesisElement } from "vue-kinesis";
// import BetaSchools from "../components/BetaSchools.vue";

const homeContainer = styled.div`
  width: 100%;
  height: 100%;
`;

// Banner Styles
const bannerContainer = styled.div`
  width: 100%;
  height: 55vw;
  position: relative;
  padding-top: 5vw;
  background: #fbfbfb;
  transition: all 0.2s ease-in-out !important;
  @media (max-width: 700px) {
    height: 80vh;
    padding-top: 18vh;
  }
`;

const bannerImg = styled.div`
  position: absolute;
  width: 100%;
  height: 29vw;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(${bg}) bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
  transition: all 0.2s ease-in-out !important;
  @media (max-width: 700px) {
    height: 35vh;
    background-size: contain;
    transition: all 0.2s ease-in-out !important;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  color: #262626;
  font-size: 3.8vw;
  text-align: center;
  margin-top: 3vw;
  margin-bottom: 0.5vw;
  position: relative;
  z-index: 1;
  transition: all 0.2s ease-in-out !important;
  @media (max-width: 700px) {
    font-size: 34px;
    transition: all 0.2s ease-in-out !important;
  }
`;

const TitleReg = styled.h1`
  font-weight: 400;
  color: #262626;
  font-size: 3.8vw;
  text-align: center;
  margin-top: 3vw;
  margin-bottom: 1vw;
  position: relative;
  z-index: 1;
  transition: all 0.2s ease-in-out !important;
  @media (max-width: 700px) {
    font-size: 34px;
    transition: all 0.2s ease-in-out !important;
  }
`;

const subTitle = styled.h3`
  font-weight: 400;
  color: #262626;
  font-size: 2.3vw;
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: 5vw;
  transition: all 0.2s ease-in-out !important;
  @media (max-width: 700px) {
    font-size: 22px;
    transition: all 0.2s ease-in-out !important;
  }
`;

const servicesContainer = styled.div`
  width: 100%;
  height: auto;
`;

// const systemFeatures = styled.div`
//   min-height: 50vw;
//   padding: 8vw 0;
//   @media (max-width: 700px) {
//     height: auto;
//     width: 100%;
//     padding: 8vh 0;
//   }
// `;

// const serveEveryone = styled.div`
//   min-height: 50vw;
//   padding: 8vw 0;
//   @media (max-width: 700px) {
//     padding: 10vh 0;
//   }
// `;

const headerContainer = styled.div`
  height: 55vw;
  width: 100%;
  padding: 9vw 0 3vw 0;
  @media (max-width: 700px) {
    height: auto;
    width: 100%;
    padding: 15vh 0 10vh 0;
  }
`;

// const wTitle = styled.h1`
//   font-family: "SF Pro Display Regular", sans-serif;
//   font-weight: 500;
//   color: #000000;
//   font-size: 2.8vw;
//   text-align: left;
//   margin-top: 0vw;
//   margin-bottom: 3vw;
//   position: relative;
//   z-index: 1;
//   background: -webkit-linear-gradient(
//     80deg,
//     rgba(33, 199, 30, 255) 0%,
//     rgba(101, 235, 101, 255) 100%
//   );
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   @media (max-width: 700px) {
//     font-size: 20px !important;
//     margin-top: 10vh;
//     text-align: center;
//   }
// `;

// const wSubTitle = styled.h3`
//   font-family: "SF Pro Display Regular", sans-serif;
//   font-weight: 500;
//   color: #000000;
//   font-size: 1.4vw;
//   text-align: left;
//   position: relative;
//   line-height: 1.7;
//   z-index: 1;
//   @media (max-width: 700px) {
//     font-size: 18px !important;
//     text-align: center;
//   }
// `;

// const svgContainer = styled.div`
//   position: relative;
//   height: 35vw;
//   width: 35vw;
//   @media (max-width: 700px) {
//     height: 40vh;
//     width: 50vh;
//     margin: 0 auto;
//   }
// `;

export default {
  name: "Home",
  components: {
    homeContainer,
    bannerContainer,
    bannerImg,
    Title,
    TitleReg,
    subTitle,

    servicesContainer,
    // serveEveryone,
    // systemFeatures,
    headerContainer,
    // wTitle,
    // wSubTitle,
    // svgContainer,

    // KinesisContainer,
    // KinesisElement,
    // BetaSchools,
  },
  data() {
    return {
      schooLeaderLeftWindow: require("../assets/images/school-leader/svg - left window.svg"),
      schooLeaderMidWindow: require("../assets/images/school-leader/svg - mid window.svg"),
      schooLeaderPerson: require("../assets/images/school-leader/svg - only person.svg"),
      schooLeaderRightWindow: require("../assets/images/school-leader/svg - right window.svg"),
      schoolLeaderBg: require("../assets/images/school-leader/svg - just background.svg"),

      teacherBg: require("../assets/images/teacher/svg - only background.svg"),
      teacherBooks: require("../assets/images/teacher/svg - only book.svg"),
      teacherCam: require("../assets/images/teacher/svg - only cam.svg"),
      teacherLaptop: require("../assets/images/teacher/svg - only laptop.svg"),
      teacherPerson: require("../assets/images/teacher/svg - only person.svg"),
      teacherTable: require("../assets/images/teacher/svg - only table.svg"),
      teacherTv: require("../assets/images/teacher/svg - only tv.svg"),

      studentBg: require("../assets/images/students/svg - only background.svg"),
      studentLeftGirl: require("../assets/images/students/svg - left girl.svg"),
      studentMidBoy: require("../assets/images/students/svg - middle boy.svg"),
      studentMidGirl: require("../assets/images/students/svg - middle girl.svg"),
      studentTable: require("../assets/images/students/svg - only table.svg"),
      studentBoy: require("../assets/images/students/svg - right boy.svg"),

      parentBg: require("../assets/images/parents-and-partners/svg - only background.svg"),
      parentFam: require("../assets/images/parents-and-partners/svg - only fam.svg"),

      leaders: require("../assets/images/icons/svg - school leaders.svg"),
      teachers: require("../assets/images/icons/svg - teachers.svg"),
      students: require("../assets/images/icons/png - student.png"),
      parents: require("../assets/images/icons/svg - parents.svg"),
      leaderFeature: [
        {
          id: 0,
          img: require("../assets/images/services/school-ecommerce250.png"),
          alt: "School Ecommerce",
          name: "School Ecommerce",
        },
        {
          id: 1,
          img: require("../assets/images/services/school-analytic-250.png"),
          alt: "School Analytics",
          name: "School Analytics",
        },
        {
          id: 2,
          img: require("../assets/images/services/virtual-id-250.png"),
          alt: "Virtual Id",
          name: "Virtual Id",
        },
        {
          id: 3,
          img: require("../assets/images/services/digital-wallet-250.png"),
          alt: "Digital Wallet",
          name: "Digital Wallet",
        },
        {
          id: 4,
          img: require("../assets/images/services/student-tracking-250.png"),
          alt: "Student Tracking",
          name: "Student Tracking",
        },
        {
          id: 5,
          img: require("../assets/images/services/no-bullying-250.png"),
          alt: "No Bullying",
          name: "No Bullying",
        },
        // Missing 'School Communictions'
      ],
      teacherFeature: [
        {
          id: 0,
          img: require("../assets/images/services/anti-cheating-250.png"),
          alt: "Anti-cheating",
          name: "Anti-cheating",
        },
        {
          id: 1,
          img: require("../assets/images/services/asynchro-250.png"),
          alt: "Asynchro",
          name: "Asynchro",
        },
        {
          id: 2,
          img: require("../assets/images/services/face-recognition250.png"),
          alt: "Face Recognition",
          name: "Face Recognition",
        },
        {
          id: 3,
          img: require("../assets/images/services/gamification-250.png"),
          alt: "Gamification",
          name: "Gamification",
        },
        {
          id: 4,
          img: require("../assets/images/services/scorm-integration250.png"),
          alt: "Scorm Integration",
          name: "Scorm Integration",
        },
        {
          id: 5,
          img: require("../assets/images/services/student-tracking-250.png"),
          alt: "Student Tracking",
          name: "Student Tracking",
        },
        {
          id: 6,
          img: require("../assets/images/services/no-bullying-250.png"),
          alt: "No Bullying",
          name: "No Bullying",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/home.scss";
@import "@/assets/styles/services.scss";
</style>
