import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/manage-teach-and-learn',
    name: 'ManageTeachLearn',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "manageteachlearn" */ '../views/ManageTeachLearn.vue'),
    // overwrite default transition
    // meta: { transition: 'zoom' },
  },
  {
    path: '/reach-to-your-community',
    name: 'ReachYourCommunity',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "reachyourcommunity" */ '../views/ReachYourCommunity.vue'),
    // overwrite default transition
    // meta: { transition: 'zoom' },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    // overwrite default transition
    // meta: { transition: 'zoom' },
  },
  {
    path: '/services',
    name: 'Services2',
    // route level code-splitting
    // this generates a separate chunk (services.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "services2" */ '../views/Services2.vue'),
    // overwrite default transition
    // meta: { transition: 'zoom' },
  },
  {
    path: '/whitestrings-for-business',
    name: 'WhiteStringsBusiness',
    // route level code-splitting
    // this generates a separate chunk (services.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "whitestringsbusiness" */ '../views/WhiteStringsBusiness.vue'),
    // overwrite default transition
    // meta: { transition: 'zoom' },
  },
  {
    path: '/book-a-demo',
    name: 'BookADemo',
    // route level code-splitting
    // this generates a separate chunk (bookademo.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BookADemo.vue'),
    // overwrite default transition
    // meta: { transition: 'zoom' },
  },
]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
  mode: 'history',
})

export default router
