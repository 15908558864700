<template>
  <b-navbar class="navBar" type="light" :class="{ onScroll: !view.topOfPage }">
    <router-link to="/" class="logo">
      <img :src="logo" alt="whiteStrings" class="logoWt" />
      <!-- <span>white<b>Strings</b></span> -->
    </router-link>

    <Slide
      class="slide"
      :closeOnNavigation="true"
      right
      v-if="$screen.width <= 700"
    >
      <menuList class="ml-auto">
        <menuItem v-for="menu in menus" :key="menu.id">
          <router-link :to="menu.path">
            {{ menu.name }}
          </router-link>
        </menuItem>
      </menuList>
    </Slide>

    <!-- Right aligned nav items -->
    <menuList v-else>
      <menuItem v-for="menu in menus" :key="menu.id">
        <router-link :to="menu.path">
          {{ menu.name }}
        </router-link>
      </menuItem>
    </menuList>
  </b-navbar>
</template>
<script>
import styled from "vue-styled-components";
import { Slide } from "vue-burger-menu";

const menuList = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  list-style: none;
  margin: 0;
`;
const menuItem = styled.li``;

export default {
  name: "Navigation",
  data() {
    return {
      view: {
        topOfPage: true,
      },
      logo: require("../assets/images/logo/ws_logo.png"),
      menus: [
        {
          id: 0,
          path: "/manage-teach-and-learn",
          name: "Manage, Teach & Learn",
        },
        {
          id: 1,
          path: "/reach-to-your-community",
          name: "Reach to your Community",
        },
        {
          id: 2,
          path: "/services",
          name: "Services",
        },
        {
          id: 3,
          path: "/whitestrings-for-business",
          name: "WhiteStrings for Business",
        },
        {
          id: 4,
          path: "/about",
          name: "About Us",
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  components: {
    menuList,
    menuItem,
    Slide,
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 0) {
        if (this.view.topOfPage) this.view.topOfPage = false;
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
      }
    },
  },
};
</script>