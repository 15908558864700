<template>
  <div id="app">
    <div id="nav">
      <Navigation/>
    </div>
    <main id="page-wrap">
    <vue-page-transition name="fade-in-up" class="page-trans-cont">
      <router-view/>
    </vue-page-transition>
    </main>
    <FooterContent/>
  </div>
</template>
<script>
import Navigation from '@/views/Navigation';
import FooterContent from '@/views/FooterContent';

export default {
  name:"App",
  components: {
    Navigation,
    FooterContent
  }
}
</script>
<style lang="scss" scoped>
.page-trans-cont {
  height: 100% !important;
}
</style>